import { store } from '../store';

export function downloadAuthorizedFile(url: string) {
  const internalUrls = [process.env.VUE_APP_RPT_API_URL as string, process.env.VUE_APP_API_URL as string];
  const targetOrigin = new URL(url).origin;
  if (!store.state.authTokens || internalUrls.every((u) => new URL(u).origin !== targetOrigin)) {
    // external url, use iframe
    return downloadFile(url);
  }

  // create the form element
  const form = document.createElement('form');
  form.classList.add('temp-form');
  // set the action attribute of the form
  form.action = url;
  // set the method attribute of the form
  form.method = 'POST';
  // set the style of the form to display:none
  form.style.display = 'none';

  // add hidden field 'authorization'
  const authInput = document.createElement('input');
  authInput.type = 'hidden';
  authInput.name = 'authorization';
  authInput.value = 'Bearer ' + (store.state.authTokens?.accessToken.token ?? '');
  form.appendChild(authInput);

  // send form output to iframe
  const iframe = getDlIframe();
  form.target = iframe.name;

  // append the form to the container element
  document.body.appendChild(form);

  try {
    // submit the form
    form.submit();
  } finally {
    // remove the form
    document.body.removeChild(form);
  }
}

export function downloadFile(url: string) {
  const iframe = getDlIframe();
  // set the src attribute of the iframe
  iframe.src = url;
}

function getDlIframe(): HTMLIFrameElement {
  // create the iframe element
  let iframe = document.getElementById('temp-dl-iframe-global') as HTMLIFrameElement;
  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.id = 'temp-dl-iframe-global';
    iframe.name = 'temp-dl-iframe-global';
    // set the style of the iframe to display:none
    iframe.style.display = 'none';
    // append the iframe to the container element
    document.body.appendChild(iframe);
  }
  return iframe;
}
