












































import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios';
import moment from 'moment';
import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import Beta from '@/components/util/Beta.vue';
import { InactivityWatcher } from '@/inactivityWatcher';
import { MUT_SNACKBAR } from '@/store';
import { downloadAuthorizedFile } from '@/utils/downloadFile';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

@Component({
  components: {
    UiButton,
    UiDatePicker,
    UiToggle,
    UiLoading,
    Beta,
  },
})
export default class Export extends BaseVue {
  public fromDate = '';
  public toDate = '';
  public isLoading = false;
  public exportMode = 'Transaction Lines';
  public isNew = true;

  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;

  mounted() {
    const queryParams = this.$route.query;
    if (queryParams.datawarehouse && typeof queryParams.datawarehouse === 'string') {
      this.isNew = queryParams.datawarehouse === 'true';
    }
  }

  isValidDate(dateString: string) {
    // Parse the date string into a Date object
    var dateObj = new Date(dateString);

    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  get isValid() {
    return this.isValidDate(this.fromDate) && this.isValidDate(this.toDate);
  }

  public async downloadCSV() {
    this.isLoading = true;

    requestParentToKeepActive('report', true);
    const inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
      () => this.isLoading && document.contains(this.$el)
    );
    try {
      let exportUrl = this.baseUrl + 'v2/export/transactions?orgId=' + this.$store.state.currentOrg.id;

      if (this.fromDate) {
        exportUrl = exportUrl + `&startDate=${this.fromDate}`;
      }

      if (this.toDate) {
        exportUrl = exportUrl + `&endDate=${this.toDate}`;
      }

      const resp = await axios({ method: 'post', url: exportUrl, withCredentials: true });
      if (resp.status === 200) {
        const exportIds = resp.data.exportIds;
        const downloadUrlPromises = [] as any[];
        exportIds?.forEach((x: any) => {
          downloadUrlPromises.push(
            axios.get(`${this.baseUrl}v2/orgs/${this.$store.state.currentOrg.id}/exports/${x}?rawUrl=true`, {
              withCredentials: true,
            })
          );
        });
        const downloadUrls = await Promise.all(downloadUrlPromises);
        downloadUrls.forEach((x: any) => {
          this.downloadFile(x.data);
        });
      } else {
        this.showErrorMessage();
      }
    } finally {
      this.isLoading = false;
      requestParentToKeepActive('report', false);
      inactivityWatcherKeepActive?.dispose();
    }
  }

  downloadFile = downloadAuthorizedFile;

  showErrorMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'error',
      message: 'Failed to export. Try again later',
    });
  }

  showSuccessMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'success',
      message: 'File download completed',
    });
  }

  toggleUi(val: boolean) {
    this.$emit('toggleUi', val);
    localStorage.setItem('exportsUi', val ? 'new' : 'old');
  }
}
